import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

// redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import { setAuthorization } from '../../../src/helpers/api_helper';
import { postResetPassword } from '../../../src/helpers/fakebackend_helper';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import logoLight from "../../assets/images/logo-light.png";

const ResetPasswordPage = ({ history }) => {
    const [showpwd, setShowPwd] = useState(false); // Password visibility toggle
    const dispatch = useDispatch();
    const location = useLocation();
    const { search } = location;
    const searchParams = new URLSearchParams(location.search);
    const access_token = searchParams.get('token');
    const queryParams = new URLSearchParams(search);
    const token = queryParams.get("token"); // This gets the token from the URL

    // Formik setup
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your password"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please Confirm Your Password'),
        }),
        onSubmit: (values) => {
            // const { password } = values.password;
            const { password, confirmPassword } = values;
            setAuthorization(access_token);
            postResetPassword({ token, new_password: password, confirm_password: confirmPassword })
                .then(res => {
                    if (res.status === '200') {
                        // Show success Swal message
                        Swal.fire({
                            icon: 'success',
                            title: 'Password Reset Successful!',
                            text: 'Your password has been reset successfully.',
                            confirmButtonText: 'Go to Login',
                            confirmButtonColor: '#3085d6',
                        }).then(() => {
                            setAuthorization("");  // Clear authorization token
                            history.push("/login");  // Redirect to success page
                        });
                    }
                })
                .catch(err => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.response?.data?.message || 'Something went wrong!',
                    });
                });
        },
    });

    const togglePasswordVisibility = () => {
        setShowPwd(prevState => !prevState);
    };

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/home" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="30" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Reset Password</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create a new password for your account.</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <form onSubmit={validation.handleSubmit}>
                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">New Password</Label>
                                                    <Input
                                                        type={showpwd ? "text" : "password"}
                                                        id="password-input"
                                                        name="password"
                                                        placeholder="Enter New Password"
                                                        aria-label="New Password"
                                                        value={validation.values.password}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={validation.touched.password && validation.errors.password}
                                                    />
                                                    <FormFeedback>{validation.errors.password}</FormFeedback>
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password-confirm">Confirm Password</Label>
                                                    <Input
                                                        type={showpwd ? "text" : "password"}
                                                        id="password-confirm"
                                                        name="confirmPassword"
                                                        placeholder="Confirm New Password"
                                                        aria-label="Confirm Password"
                                                        value={validation.values.confirmPassword}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
                                                    />
                                                    <FormFeedback>{validation.errors.confirmPassword}</FormFeedback>
                                                </div>

                                                <div className="mt-4">
                                                    <Button
                                                        color="success"
                                                        className="btn btn-success w-100"
                                                        type="submit"
                                                    >
                                                        Reset Password
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Remembered your password? <Link to="/login" className="fw-semibold text-primary text-decoration-underline">Click here</Link></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(ResetPasswordPage);
