import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Table, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Swal from 'sweetalert2';
import { getAllBids, getLoggedInUser, getAllAdminAuctions, getAllAuctions, getAllVideos } from "../../../../helpers/fakebackend_helper"; // Assuming you have a helper function to fetch auctions and videos
import axios from 'axios';

const AuctionManagement = () => {
    const [auctions, setAuctions] = useState([]);
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        getAllAdminAuctions().then(auctionRes => {
            setAuctions(auctionRes);
        });
        getAllVideos().then(video => {
            setVideos(video)
        })

    };

    const approveAuction = (auctionId) => {
        axios.post(`/api/auctions/${auctionId}/approve`)
            .then(response => {
                Swal.fire({
                    title: 'Approved!',
                    text: response.message,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                loadData(); // Refresh auctions list after approval
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to approve auction. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    };

    const denyAuction = (auctionId) => {
        axios.post(`/api/auctions/${auctionId}/deny`)
            .then(response => {
                Swal.fire({
                    title: 'Denied!',
                    text: response.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                loadData(); // Refresh auctions list after denial
            })
            .catch(error => {
                console.log('short leg', error)
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to deny auction. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    };



    const getVideoTitle = (videoId) => {
        const video = videos.find(v => v.id === videoId);
        return video ? video.title : "No Video Title";
    };
    const getVideoVideo = (videoId) => {
        const video = videos.find(v => v.id === videoId);
        return video ? video.video_url : "[1,2]";
    }

    return (
        <React.Fragment>
            <section className="section pb-0" id="hero">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} sm={10}>
                            <div className="text-center">
                                <h2>Manage Auctions</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="table-responsive" style={{ margin: "80px" }}>
                <Table className="table-borderless table-responsive mb-0" style={{ textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th style={{ width: '4%' }}>ID#</th>
                            {/* Change Auction Title to Video Title */}
                            <th style={{ width: '15%' }}>Video Title</th>
                            <th style={{ width: '20%' }}>Video</th>
                            <th style={{ width: '12%' }}>Start Time</th>
                            <th style={{ width: '12%' }}>End Time</th>
                            <th style={{ width: '10%' }}>Reserve Price</th>
                            <th style={{ width: '10%' }}>Shipping Cost</th>
                            <th style={{ width: '8%' }}>Status</th>
                            <th style={{ width: '10%' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auctions.map((auction) => (
                            <tr key={auction.id}>
                                <td>{auction.id}</td>
                                {/* Use video title fetched based on video_id */}
                                <td>{getVideoTitle(auction.video_id)}</td> {/* Get video title by matching video_id */}
                                <td style={{ width: '20%' }}>
                                    <video muted controls style={{ width: '200px', height: '180px' }}>
                                        <source src={JSON.parse(getVideoVideo(auction.video_id))[1]} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </td>
                                <td>{new Date(auction.start_datetime).toLocaleString()}</td>
                                <td>{new Date(auction.end_datetime).toLocaleString()}</td>
                                <td>{auction.reserve_price}</td>
                                <td>{auction.shipping_cost}</td>
                                <td>{auction.status}</td>
                                <td>
                                    <UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-primary">
                                            <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => approveAuction(auction.id)}>
                                                Approve
                                            </DropdownItem>
                                            <DropdownItem onClick={() => denyAuction(auction.id)}>
                                                Deny
                                            </DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    );
};

export default AuctionManagement;
